<template>
  <div class="nav-list">
    <div
        @click="toDetail(item, i)"
        class="nav-item-child"
        :class="$route.path === item.path ? 'active' : ''"
        v-for="(item, i) in navList"
        :key="i"
        >
        <div class="nav-cont">
            <span>{{ item.name }}</span>
            <img class="nav-cont-active-pic" src="@/assets/home/nav-active-logo.png" alt="">
        </div>
        
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    toDetail(item, i) {
      if(this.$route.path != item.path){
        this.$router.push({
          path: item.path,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-list {
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
  display: flex;
  box-sizing: border-box;
  padding: 30px 150px;
  justify-content: space-between;
  .nav-item-child{
    flex-basis: 23%;
    height: 58px;
    margin-bottom: 20px;
    .nav-cont{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        background-image: url('../../../assets/home/nav-item-active-line.png');
        background-repeat: no-repeat;
        background-size: 100% 2px;
        background-position: bottom;
        .nav-cont-active-pic{
            width: 32px;
            height: 20px;
        }
    }
  }
}
</style>


