<template>
  <div class="footer">
    <div class="container">
      <div class="footer-logo">
        <img class="footer-logo-pic" src="@/assets/home/logo.png" alt="" />
      </div>
      <div class="footer-item-list">
        <div class="footer-item">
          <div class="footer-item-title">
            <span>数字电力</span>
            <img class="fi-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <img class="fi-line" src="@/assets/home/item-line.png" alt="" />
          </div>
          <div class="footer-content">
            <div class="fc-item" @click="toDetail({path: '/szdl/1'})">配电站房智能辅助系统</div>
            <div class="fc-item" @click="toDetail({path: '/szdl/2'})">变电站/电厂立体巡检系统</div>
            <div class="fc-item" @click="toDetail({path: '/szdl/3'})">智慧配电网</div>
            <div class="fc-item" @click="toDetail({path: '/szdl/4'})">数智党建</div>
          </div>
        </div>

        <div class="footer-item">
          <div class="footer-item-title">
            <span>智慧运营</span>
            <img class="fi-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <img class="fi-line" src="@/assets/home/item-line.png" alt="" />
          </div>
          <div class="footer-content">
            <div class="fc-item" @click="toDetail({path: '/zhyw/1'})">充电运营</div>
            <div class="fc-item" @click="toDetail({path: '/zhyw/2'})">能源聚合</div>
            <div class="fc-item" @click="toDetail({path: '/zhyw/3'})">数据运营</div>
            <div class="fc-item" @click="toDetail({path: '/zhyw/4'})">车辆检测</div>
          </div>
        </div>

        <div class="footer-item">
          <div class="footer-item-title">
            <span>产品服务</span>
            <img class="fi-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <img class="fi-line" src="@/assets/home/item-line.png" alt="" />
          </div>
          <div class="footer-content">
            <div class="fc-item">硬件设备</div>
            <div class="fc-item">软件平台</div>
          </div>
        </div>

        <div class="footer-item">
          <div class="footer-item-title">
            <span>关于智融</span>
            <img class="fi-arrow" src="@/assets/home/item-arrow.png" alt="" />
            <img class="fi-line" src="@/assets/home/item-line.png" alt="" />
          </div>
          <div class="footer-content">
            <div class="footer-content-split">
              <div class="fc-item" @click="toDetail({path: '/gyzr/1'})">公司简介</div>
              <div class="fc-item" @click="toDetail({path: '/gyzr/4'})">企业文化</div>
              <div class="fc-item" @click="toDetail({path: '/gyzr/2'})">荣誉资质</div>
              <div class="fc-item" @click="toDetail({path: '/gyzr/3'})">合作伙伴</div>
            </div>
            <div class="footer-content-split">
              <div class="fc-item" @click="toDetail({path: '/gyzr/5'})">党建引领</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="other">
      <p class="copyright">版权所有: 江苏智融能源科技有限公司</p>
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >苏ICP备2023008985号-1</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {};
  },

  mounted() {},

  methods:{
    toDetail(item, i) {
      if(this.$route.path != item.path){
        this.$router.push({
          path: item.path,
        });
      
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  margin: 0 auto;
  background-image: url("../../assets/images/footer-bg.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 80px 160px;

  .other {
    display: flex;
    font-weight: 400;
    font-size: 18px;
    color: #7d7d7d;
    .copyright {
      margin-right: 10px;
    }
    a {
      color: #7d7d7d;
    }
  }

  .container {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
    .footer-logo {
      width: 100%;
      height: 40px;
      margin-bottom: 40px;
      .footer-logo-pic {
        width: 130px;
        height: 40px;
      }
    }
    .footer-item-list {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .footer-item {
        flex-basis: 22%;
        .footer-item-title {
          position: relative;
          width: 100%;
          height: 50px;
          line-height: 50px;
          color: #fff;
          font-size: 32px;
          margin-bottom: 40px;
          .fi-arrow {
            position: absolute;
            width: 32px;
            height: 20px;
            top: 10px;
            right: 20px;
          }
          .fi-line {
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0;
          }
        }
        .footer-content {
          width: 100%;
          font-weight: 400;
          font-size: 18px;
          color: #d4e6ff;
          display: flex;
          flex-wrap: wrap;
          .fc-item {
            width: 100%;
            margin-bottom: 20px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          .footer-content-split {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
