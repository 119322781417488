<template>
  <div class="nav" id="top-navbar">
    <div class="container">
      <div class="logo">
        <!--<img src="@/assets/logo.png" alt="" height="50px" width="50px" style="margin:15px 0 0 0">-->
        <div style="margin-right: 50px" @click="$router.push('/home')">
          <div class="logo_pic"></div>
          <!-- <img src="@/assets/home/logo.png" class="logo_pic" alt=""> -->
        </div>
        <div class="nav-container">
          <div class="title-container" id="titleContainer">
            <div
              @click="toDetail(item, i)"
              class="nav-item"
              :class="$route.path.indexOf(item.path) > -1 ? 'active' : ''"
              v-for="(item, i) in navList"
              :key="i"
            >
              {{ item.name }}
            </div>
            <div class="child-nav-list" id="childNavList">
              <div class="child-nav-container">
                <div
                  class="cn-item"
                  @click="toDetail(item, index)"
                  v-for="(item, index) in nowNavItemList"
                  :class="[{'cn-active': $route.path.indexOf(item.path) > -1 },{'cn-item-4': nowNavItemList.length == 4}]"
                  :key="index"
                >
                  <div class="nav-item-cont">
                    <span>{{ item.name }}</span>
                    <img
                      class="nav-cont-active-pic"
                      v-if="$route.path.indexOf(item.path) > -1"
                      src="@/assets/home/nav-active-logo.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="login">
        <div class="btn">
          <div class="btn-img btn-img1"></div>
          <div class="btn-img btn-img2"></div>
          <div class="btn-img btn-img3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import navItemList from "./home/nav-item-list.vue";

export default {
  components: { navItemList },
  name: "Nav",
  data() {
    return {
      navList: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "数字电力",
          path: "/szdl",
        },
        {
          name: "智慧运营",
          path: "/zhyw",
        },
        // {
        //   name: "产品服务",
        //   path: "/cpfw",
        // },
        {
          name: "关于智融",
          path: "/gyzr",
          navList: [
            {
              name: "企业简介",
              path: "/gyzr/1",
            },
            {
              name: "荣誉资质",
              path: "/gyzr/2",
            },
            {
              name: "合作伙伴",
              path: "/gyzr/3",
            },
            {
              name: "企业文化",
              path: "/gyzr/4",
            },
            {
              name: "党建引领",
              path: "/gyzr/5",
            },
          ],
        },
        {
          name: "人才招聘",
          path: "/rczp",
        },
      ],
      allNavItemList: {
        szdl: [
          {
            name: "配电站房智能辅助",
            path: "/szdl/1",
          },
          {
            name: "变电站/厂立体巡检系统",
            path: "/szdl/2",
          },
          {
            name: "智慧配电网",
            path: "/szdl/3",
          },
          {
            name: "数智党建",
            path: "/szdl/4",
          },
        ],
        zhyw: [
          {
            name: "充电运营",
            path: "/zhyw/1",
          },
          {
            name: "能源聚合",
            path: "/zhyw/2",
          },
          {
            name: "数据运营",
            path: "/zhyw/3",
          },
          {
            name: "车辆检测",
            path: "/zhyw/4",
          },
        ],
        cpfw: [],
        gyzr: [
          {
            name: "企业简介",
            path: "/gyzr/1",
          },
          {
            name: "荣誉资质",
            path: "/gyzr/2",
          },
          {
            name: "合作伙伴",
            path: "/gyzr/3",
          },
          {
            name: "企业文化",
            path: "/gyzr/4",
          },
          {
            name: "党建引领",
            path: "/gyzr/5",
          },
        ],
        rczp: [],
      },

      nowNavItemList: [],
      register: {},
    };
  },
  computed: {
    ...mapGetters(["avatar", "name", "roles", "isPay"]),
  },
  watch:{
    $route() {
      let navbar = document.getElementById("top-navbar");
      if(this.$route.path == '/gyzr/5' || this.$route.path == '/szdl/4'){
        navbar.classList.add("scrolledOnlyFont");
      } else {
        navbar.classList.remove("scrolledOnlyFont");
      }
    },
  },

  mounted() {
    console.log("$route.path>>>", this.$route.path);

    this.$nextTick(() => {
      const navbar = document.getElementById("top-navbar");
      const nowNavListDom = document.getElementById("childNavList");
      const titleContainer = document.getElementById("titleContainer");

      if(this.$route.path == '/gyzr/5' || this.$route.path == '/szdl/4'){
        navbar.classList.add("scrolledOnlyFont");
      } else {
        navbar.classList.remove("scrolledOnlyFont");
      }

      window.addEventListener("scroll", () => {
        if (window.scrollY > 300) {
          navbar.classList.add("scrolled");
        } else {
          navbar.classList.remove("scrolled");
        }
      });

      const children = document.querySelectorAll(".nav-item");
      let _this = this;

      children.forEach((child) => {
        child.addEventListener("mouseover", function () {
          navbar.classList.add("scrolled");

          if (this.innerHTML.replace(/^\s*|\s*$/g, "") == "首页" || this.innerHTML.replace(/^\s*|\s*$/g, "") == "人才招聘" || this.innerHTML.replace(/^\s*|\s*$/g, "") == "产品服务") {
            nowNavListDom.style.display = "none";
          } else {
            nowNavListDom.style.display = "block";
          }
          if (this.innerHTML.replace(/^\s*|\s*$/g, "") == "数字电力") {
            _this.nowNavItemList = _this.allNavItemList["szdl"];
          } else if (this.innerHTML.replace(/^\s*|\s*$/g, "") == "智慧运营") {
            console.log("tab页选中了智慧运营");
            _this.nowNavItemList = _this.allNavItemList["zhyw"];
          } 
          // else if (this.innerHTML.replace(/^\s*|\s*$/g, "") == "产品服务") {
          //   console.log("tab页选中了产品服务");
          //   _this.nowNavItemList = _this.allNavItemList["cpfw"];
          // } 
          else if (this.innerHTML.replace(/^\s*|\s*$/g, "") == "关于智融") {
            console.log("tab页选中了关于智融");
            _this.nowNavItemList = _this.allNavItemList["gyzr"];
          } 
          // else if (this.innerHTML.replace(/^\s*|\s*$/g, "") == "人才招聘") {
          //   console.log("tab页选中了人才招聘");
          //   _this.nowNavItemList = _this.allNavItemList["rczp"];
          // }
        });

        // 鼠标移出后隐藏
        child.addEventListener("mouseout", function () {
          // console.log("44444444444444444444");
        });
      });

      // titleContainer.addEventListener("mouseout", function (event) {
      //   console.log("787878787878")
      //   console.log("77777777777777777777>>>>>>>>>", !nowNavListDom.contains(document.querySelector(':hover')))
      //   setTimeout(function () {
      //     if(!event.relatedTarget ||
      //         !nowNavListDom.contains(event.relatedTarget)){
      //       nowNavListDom.style.display = "none";
      //       if (window.scrollY > 300) {
      //         navbar.classList.add("scrolled");
      //       } else {
      //         navbar.classList.remove("scrolled");
      //       }
      //     }
      //   }, 100);
      // });


      nowNavListDom.addEventListener("mouseover", function () {
        this.style.display = "block";
        navbar.classList.add("scrolled");
      });

      nowNavListDom.addEventListener("mouseout", function (event) {
        setTimeout(function () {
          if (
            !titleContainer.contains(document.querySelector(":hover")) &&
            (!event.relatedTarget ||
              !nowNavListDom.contains(event.relatedTarget))
          ) {
            nowNavListDom.style.display = "none";
            if (window.scrollY > 300) {
              navbar.classList.add("scrolled");
            } else {
              navbar.classList.remove("scrolled");
            }
          }
        }, 300);
      });

      // console.log("titleContainer>>>", titleContainer);

      // titleContainer.addEventListener('mouseover', function() {
      //   console.log("5555555555555555555555")
      //     let nowNavListDom = document.getElementById('childNavList');
      //     console.log("555nowNavListDom>>",nowNavListDom)
      // });

      

      // let navListItem = document.querySelector(".child-nav-list");
      // console.log("navListItem>>", navListItem);

      // navListItem.addEventListener("mouseover", function () {
      //   this.style.display = "block";
      // });

      // navListItem.addEventListener("mouseover", function () {
      //   this.style.display = "none";
      // });
    });
  },

  methods: {
    toDetail(item, i) {
      if(this.$route.path != item.path){
        this.$router.push({
          path: item.path,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  height: 75px;
  z-index: 100;
  /* background: #ffffff; */
  width: 99.9%;
  max-width: 1920px;
  margin: 0 auto;
  color: #fff;

  .container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    position: relative;

    .logo {
      display: flex;
      width: 90%;

      .logo_pic {
        display: inline-block;
        width: 131px;
        height: 39px;
        margin-top: 20px;
        background-image: url("../../assets/home/logo.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
      }

      .nav-container {
        flex: 1;
        text-align: right;

        .title-container {
          display: inline-block;
          width: 600px;
          .nav-item {
            display: inline-block;
            width: 100px;
            height: 72px;
            line-height: 72px;
            text-align: center;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            position: relative;
          }
          .child-nav-list {
            position: fixed;
            display: none;
            width: 100%;
            /* height: 120px; */
            left: 0;
            top: 75px;
            background-color: rgba(#f2f6ff, 0.8);
            .child-nav-container {
              width: 100%;
              height: 100%;
              display: flex;
              box-sizing: border-box;
              padding: 20px 120px;
              flex-wrap: wrap;
              .cn-item {
                flex-basis: 18%;
                height: 80px;
                line-height: 80px;
                text-align: center;
                box-sizing: border-box;
                padding: 10px 50px;
                cursor: pointer;
                margin-right: 30px;

                .nav-item-cont {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: space-between;
                  background-image: url("../../assets/home/nav-item-line.png");
                  background-size: 100% 2px;
                  background-position: bottom;
                  background-repeat: no-repeat;
                }
                .nav-cont-active-pic {
                  margin-top: 30px;
                  width: 32px;
                  height: 20px;
                }
              }
              .cn-item-4{
                flex-basis: 23%;
              }
              .cn-active {
                color: #1f63ea;
                .nav-item-cont {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: space-between;
                  background-image: url("../../assets/home/nav-item-active-line.png");
                  background-size: 100% 2px;
                  background-position: bottom;
                  background-repeat: no-repeat;
                }
              }
            }
          }
          &:hover {
            .child-nav-list {
              display: block;
            }
          }
        }
      }
    }

    .login {
      position: absolute;
      right: -5%;
      display: flex;

      .btn {
        display: flex;
        box-sizing: border-box;
        padding-top: 10px;

        div {
          width: 50px;
          height: 72px;
          line-height: 72px;
          text-align: center;
          cursor: pointer;
        }
        .btn-img {
          width: 32px;
          height: 32px;
          margin-top: 10px;
          margin-right: 10px;
        }
        .btn-img1 {
          background-image: url("../../assets/home/gongzhonghao.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
        }

        .btn-img2 {
          background-image: url("../../assets/home/email.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
        }

        .btn-img3 {
          background-image: url("../../assets/home/language.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center;
        }
      }
    }
  }

  .active {
    border-bottom: 3px solid #1f63ea;
  }
}

.nav.scrolled {
  background-color: #fff;
  box-shadow: 0 3px 8px 1px rgba(#000, 0.1);
  color: #1d1d1d;
  .container {
    .logo {
      .logo_pic {
        background-image: url("../../assets/home/logo2.png");
      }
    }
    .login {
      .btn {
        .btn-img1 {
          background-image: url("../../assets/home/gongzhonghao2.png");
        }
        .btn-img2 {
          background-image: url("../../assets/home/email2.png");
        }
        .btn-img3 {
          background-image: url("../../assets/home/language2.png");
        }
      }
    }
  }
}

.nav.scrolledOnlyFont {
  /* background-color: #fff; */
  /* box-shadow: 0 3px 8px 1px rgba(#000, 0.1); */
  color: #1d1d1d;
  .container {
    .logo {
      .logo_pic {
        background-image: url("../../assets/home/logo2.png");
      }
    }
    .login {
      .btn {
        .btn-img1 {
          background-image: url("../../assets/home/gongzhonghao2.png");
        }
        .btn-img2 {
          background-image: url("../../assets/home/email2.png");
        }
        .btn-img3 {
          background-image: url("../../assets/home/language2.png");
        }
      }
    }
  }
}
</style>
