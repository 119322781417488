import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index.vue'),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/szdl/:index?',
        name: 'Szdl',
        component: () => import('@/views/shuZiDianLi/index.vue'),
        meta: {
            title: '数字电力'
        }
    },
    {
        path: '/zhyw/:index?',
        name: 'Zhyw',
        component: () => import('@/views/zhiHuiYunWei/index.vue'),
        meta: {
            title: '智慧运维'
        }
    },
    {
        path: '/gyzr/:index?',
        name: 'Gyzr',
        component: () => import('@/views/guanyuZhiRong/index.vue'),
        meta: {
            title: '关于智融'
        }
    },
    {
        path: '/cpfw/:index?',
        name: 'Cpfw',
        component: () => import('@/views/chanpinfuwu/index.vue'),
        meta: {
            title: '产品服务'
        }
    },
    {
        path: '/rczp/:index?',
        name: 'Rczp',
        component: () => import('@/views/rencaizhaopin/index.vue'),
        meta: {
            title: '人才招聘'
        }
    },

    // {
    //     path: '/admin',
    //     name: 'Admin',
    //     component: () => import('@/views/admin/Index.vue'),
    //     meta: {
    //         title: '后台首页'
    //     },
    //     children: [
    //         {
    //             path: '/info',
    //             name: 'Info',
    //             component: () => import('@/views/admin/Info.vue'),
    //             meta: {
    //                 title: '基本信息'
    //             }
    //         }
    //     ]
    // },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition; // 对于浏览器前进或后退导致的跳转
        } else {
            return { x: 0, y: 0 }; // 对于新页面的跳转
        }
    }
})

export default router
